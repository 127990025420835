import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      name: 'NotFound',
      meta: {
        title: 'Not Found'
      },
      components: {
        default: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
      }
    }
  ]
})

router.beforeEach(async (to, _from, next) => {
  // Set page title
  const title = to.meta.title || to.name
  if (title) {
    document.title = `Microsoft EOS Insights Integrations - ${title}`
  } else {
    document.title = 'Microsoft EOS Insights Integrations'
  }
  next()
})

export default router
