import store from '@/store'
import ODataStore, { ODataState, ODataRequest } from '@/store/ODataStore'
import IMsx from './models/IMsx'
import ITimeline from './models/ITimeline'
import ITemplate from './models/ITemplate'

export interface IMsxStoreState extends Partial<ODataState<IMsx>> {
  timeline: ITimeline,
  defaultTemplate: ITemplate,
  templates: ITemplate[]
}

store.registerModule('MSX', ODataStore.extend<IMsxStoreState>({
  namespaced: true,
  state: {
    timeline: null,
    defaultTemplate: null,
    templates: []
  },
  getters: {
    timeline(state) {
      return state.timeline
    },
    templates(state) {
      return state.templates
    },
    defaultTemplate(state) {
      return state.defaultTemplate
    }
  },
  mutations: {
    setTimeline(state, timeline: ITimeline) {
      state.timeline = timeline
    },
    setDefaultTemplate(state, template: ITemplate) {
      state.defaultTemplate = template
    },
    setTemplates(state, templates: ITemplate[]) {
      state.templates = templates
    }
  },
  actions: {
    async loadTemplates({ commit, dispatch }, preferredLocale) {
      const request: ODataRequest = {
        url: 'Msx/Templates',
        params: {
          $orderby: 'userId, name'
        }
      }
      const result = await dispatch('get', request)
      commit('setTemplates', result.value)
      let defaultTemplate = result.value.filter((x: ITemplate) => {
        return x.dmclanguageCode === preferredLocale
      })
      if (!defaultTemplate || defaultTemplate.length === 0) {
        defaultTemplate = result.value.filter((x: ITemplate) => x.dmclanguageCode === 'en-US')
      }
      if (defaultTemplate && defaultTemplate.length > 0) {
        commit('setDefaultTemplate', defaultTemplate[0])
      }
    },
    async loadTimeline({ commit, dispatch }, params) {
      const request: ODataRequest = {
        url: 'Msx/Timeline',
        params: {
        }
      }
      if (params) {
        const locale = params.locale
        const tpid = params.tpid
        if (locale && !isNaN(tpid)) {
          request.url += `(locale='${locale}',tpid=${tpid})`
        } else if (!isNaN(tpid)) {
          request.url += `(locale='en-us',tpid=${tpid})`
        } else if (locale) {
          request.url += `(locale='${locale}',tpid=0)`
        }
      }
      const result = await dispatch('get', request)
      commit('setTimeline', result)
    },
    async exportTimeline({ dispatch }, params: any) {
      const request: ODataRequest = {
        url: 'Msx/export',
        responseType: 'blob',
        noLoading: true,
        headers: {
          Accept: params.format
        }
      }
      if (params) {
        const locale = params.locale
        const tpid = params.tpid
        if (locale && !isNaN(tpid)) {
          request.url += `(locale='${locale}',tpid=${tpid})`
        } else if (!isNaN(tpid)) {
          request.url += `(locale='en-us',tpid=${tpid})`
        } else if (locale) {
          request.url += `(locale='${locale}',tpid=0)`
        }
      }
      const response = await dispatch('get', request)
      const blob = new Blob([response], {
        type: response.type
      })
      const downloadUrl = window.URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.href = downloadUrl
      let documentName = 'EOSInsights_Timeline'
      if (!isNaN(params.tpid)) {
        documentName += `_${params.tpid}`
      }

      if (params.format === 'text/html') {
        documentName += '.html'
      } else if (params.format === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
        documentName += '.pptx'
      } else if (params.format === 'application/pdf') {
        documentName += '.pdf'
      }

      link.setAttribute('download', documentName)

      document.body.appendChild(link)

      link.click()
      link.remove()
    }
  }
}))
