import router from '@/router'

router.addRoutes([
  {
    path: '/msx',
    name: 'MSX',
    alias: '/MSX',
    meta: {
      title: 'MSX'
    },
    components: {
      default: () => import(/* webpackChunkName: "MSX" */ './views/MSX_Harmony.vue')
    }
  }
])
