<template>
  <router-view :key="$route.params.id" />
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      loading: true,
      showWelcomeDialog: false,
      unauthorized: false
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['Auth/accessToken']
    }
  },
  async created() {
    try {
      if (!(await this.$store.dispatch('Auth/login'))) {
        return
      }
      // await Promise.all([
      //   // load global stores
      // ]).catch((x) => {
      //   this.unauthorized = x.message.endsWith('403')
      // }).then(() => {
      if (this.$route.query.path) {
        let slash = '/'
        if (this.$route.query.path.startsWith('/')) {
          slash = ''
        }
        window.location.replace(window.location.origin + slash + this.$route.query.path)
      }
      // })
    } finally {
      this.loading = false
    }
  }
}
</script>
