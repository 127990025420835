import store from '@/store'

export interface IAuthStoreState {
  accessToken: string
}

store.registerModule<IAuthStoreState>('Auth', {
  namespaced: true,
  state: {
    accessToken: null
  },
  getters: {
    accessToken(state) {
      return state.accessToken
    }
  },
  mutations: {
    setAccessToken(state, accessToken: string) {
      state.accessToken = accessToken
    }
  },
  actions: {
    async login({ commit }) {
      const params = new URLSearchParams(window.location.search)
      const token = params.get('auth_token')
      if (token) {
        commit('setAccessToken', token)
        return token
      } else {
        commit('setError', 'Missing auth_token url parameter')
      }
    }
  }
})
